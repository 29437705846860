import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { Paragraph } from 'components/common/Styles';

import { ContentArticle820 } from 'components/Content';
import Footer from 'components/Footer/Footer';
import Seo from 'components/seo';
import Header from 'components/common/header/Header';
import ThemeProviderComponent from 'components/common/ThemeProvider';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';

const Careers = () => {
	const [isMenuOpen, toggleMenu] = useState(false);
	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};
	return (
		<>
			<Seo
				title='Careers: Are you an Engineer, UX / UI designer in Sales or Data?'
				description='We are always on the lookout for hard-working & talented people to join the BookPhysio.com team. If you are passionate about solving big problems, reach out.'
			/>
			<ThemeProviderComponent>
				<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
				<PaddingTopContainer isBurgerOpen={isMenuOpen}>
					<ContentArticle820 isOpen={isMenuOpen}>
						<Row>
							<Column>
								<H1>Careers at BookPhysio.com</H1>
								<Paragraph>
									We are always on the lookout for hard-working and talented (Engineers, UX, UI,
									Sales, Data) people to join our team.
								</Paragraph>
								<Paragraph>
									If you are passionate about challenging the status quo and want to improve the lives
									of our patients,{' '}
									<HoveredInline>
										<a
											href='https://localphysionetwork.typeform.com/to/k0sNSMDP'
											target='_blank'
											rel='noopener noreferrer'
										>
											fill in our form
										</a>
									</HoveredInline>{' '}
									and tell us why you want to work with us.
								</Paragraph>
							</Column>
							<Column>
								<StaticImage src='../images/about-team.jpg' alt='hero' width={350} height={230} />
							</Column>
						</Row>
					</ContentArticle820>
					<Footer />
				</PaddingTopContainer>
			</ThemeProviderComponent>
		</>
	);
};

const H1 = styled.h1`
	font-family: SharpGrotesk-MediumNo21, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 28px;
	line-height: 35px;
	color: #152231;
`;

const Row = styled.div`
	width: 100%;
	display: flex;
	margin: 80px 0 25px;
	@media (max-width: 767px) {
		flex-direction: column;
		margin: 50px 0 0;
	}
`;
const Column = styled.div`
	& img {
		border-radius: 6px;
	}
	&:first-child {
		flex: 0 0 52%;
		padding: 10px 0;
	}
	&:last-child {
		flex: 0 0 46%;
		padding: 0 0 0 40px;
		@media (max-width: 767px) {
			display: flex;
			justify-content: center;
			padding: 40px 0;
		}
	}
`;

const Hovered = styled(Paragraph)`
	& a {
		color: ${({ theme }) => theme.palette.purp};
		transition: all 0.3s ease;
		cursor: pointer;
		&:hover {
			color: ${({ theme }) => theme.palette.main};
			text-decoration: none;
		}
	}
`;

const HoveredInline = styled(Hovered)`
	display: inline-block;
	margin: 0;
	&:first-child {
		margin: 0;
	}
`;
export default Careers;
